import {
	AfterViewInit,
	Component,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { CoreModule, LocalizationPipe, LocalizationService } from '@abp/ng.core';
import { SettingsFilesService } from '../settings-files.service';
import { MediaFileModelDto, MediaFileType, MediaFileUploadSourceType } from '@proxy/media-files';
import { FileUploadModule, FileUpload } from 'primeng/fileupload';
import { MediaSettingsService } from '@proxy/media-settings';
import { Toaster, ToasterService } from '@abp/ng.theme.shared';
import { takeLast } from 'rxjs';

interface MediaFileModelDtoDeleteFlag extends MediaFileModelDto {
	deleted?: boolean;
}

@Component({
	standalone: true,
	selector: 'app-settings-tab',
	templateUrl: 'settings-tab.component.html',
	imports: [CoreModule, FileUploadModule],
})
export class SettingsTabComponent implements OnInit, AfterViewInit {
	@ViewChildren('mediaInput') mediaInput: QueryList<FileUpload>;

	public settingsNames: string[];
	public isLoading: { [key: string]: boolean } = {};
	public uploadedFiles: { [key: string]: MediaFileModelDtoDeleteFlag } = {};
	public existingFiles: { [key: string]: MediaFileModelDtoDeleteFlag } = {};
	private options: Partial<Toaster.ToastOptions> = { sticky: true, tapToDismiss: true };
	private userId: string;

	constructor(
		private _config: ConfigStateService,
		private _settingFileService: SettingsFilesService,
		private _mediaSettingsService: MediaSettingsService,
		private _localizationService: LocalizationService,
		private _toasterService: ToasterService
	) {
		const settings = this._config.getSettings('PMC.Media');
		this.settingsNames = Object.keys(settings);
	}

	ngOnInit(): void {
		this._config.getOne$('currentUser').subscribe(user => (this.userId = user['id']));
	}

	ngAfterViewInit(): void {
		this.mediaInput.forEach(input => {
			this.loadExisting(input.name);

			input.onSelect.subscribe(async e => {
				const file = e.currentFiles[0];
				this.uploadedFiles[input.name] = {
					file: file,
					fileType: MediaFileType.File,
					fileName: file.name,
				};
			});

			input.onRemove.subscribe(async () => {
				this.uploadedFiles[input.name] = undefined;
			});
		});
	}

	private updateMedia(formData: FormData) {
		this._settingFileService
			.updateMedia(formData)
			.pipe(takeLast(1))
			.subscribe(result => {
				this._toasterService.success(
					`Successfully updated ${this._localizationService.instant(
						'::' + formData.get('mediaSetting')
					)}!`,
					null,
					this.options
				);
				this.isLoading[formData.get('mediaSetting').toString()] = false;
			});
	}

	public saveMedia(name: string) {
		this.isLoading[name] = true;
		const localizedName = this._localizationService.instant('::' + name);
		const source = this.getTypeFromName(name);
		const formData = new FormData();

		// Referencing MediaSettingsFileUpdateDto
		formData.append('sourceUserId', this.userId);
		formData.append('mediaSetting', name);
		formData.append('mediaUploadSource', source);

		if (this.uploadedFiles[name]) {
			formData.append('file.fileType', this.uploadedFiles[name].fileType.toString());
			formData.append('file.name', localizedName);
			formData.append('file.file', this.uploadedFiles[name].file);
			formData.append('existingFileId', this.existingFiles[name]?.id ?? '');
		} else if (this.existingFiles[name] && this.existingFiles[name].deleted) {
			formData.append('existingFileId', '');
		} else {
			formData.append('existingFileId', this.existingFiles[name]?.id ?? '');
		}

		this.updateMedia(formData);
	}

	public loadExisting(name: string) {
		this._mediaSettingsService.getMedia(name).subscribe(result => {
			this.existingFiles[name] = result;
			if (this.existingFiles[name]) {
				this.existingFiles[name].deleted = false;
			}
			this.loadExisting[name] = false;
		});
	}

	public deleteItem(name: string) {
		this.existingFiles[name].deleted = true;
	}

	private getTypeFromName(name: string) {
		switch (name) {
			case 'PMC.Media.CourseCatalogue':
				return MediaFileUploadSourceType.MediaSettingsCourseCatalogue;
			case 'PMC.Media.PartnershipsGuidebook':
				return MediaFileUploadSourceType.MediaSettingsPartnershipGuidebook;
			case 'PMC.Media.PartnerHomePageImage':
				return MediaFileUploadSourceType.MediaSettingsPartnerHomePageImage;
			case 'PMC.Media.StudentHomePageImage':
				return MediaFileUploadSourceType.MediaSettingsStudentHomePageImage;
			default:
				return '';
		}
	}
}
