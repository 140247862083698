import { mapEnumToOptions } from '@abp/ng.core';

export enum MediaFileUploadSourceType {
  Unknown = 'Unknown',
  CourseImage = 'CourseImage',
  CourseIconImage = 'CourseIconImage',
  BlockVideo = 'BlockVideo',
  BlockPDF = 'BlockPDF',
  BlockFlashCardFront = 'BlockFlashCardFront',
  BlockFlashCardBack = 'BlockFlashCardBack',
  ProgramImageFile = 'ProgramImageFile',
  StudentNameChange = 'StudentNameChange',
  ProgramVideoFile = 'ProgramVideoFile',
  MediaSettingsCourseCatalogue = 'MediaSettingsCourseCatalogue',
  MediaSettingsPartnershipGuidebook = 'MediaSettingsPartnershipGuidebook',
  SupportVideos = 'SupportVideos',
  Marketing = 'Marketing',
  SpecializationIcon = 'SpecializationIcon',
  SkillListIcon = 'SkillListIcon',
  ProgramVideoThumbnailFile = 'ProgramVideoThumbnailFile',
  PartnerNameChange = 'PartnerNameChange',
  BlogPost = 'BlogPost',
  MediaSettingsPartnerHomePageImage = 'MediaSettingsPartnerHomePageImage',
  MediaSettingsStudentHomePageImage = 'MediaSettingsStudentHomePageImage',
}

export const mediaFileUploadSourceTypeOptions = mapEnumToOptions(MediaFileUploadSourceType);
